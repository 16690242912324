import {Model, Store} from 'store/Base';
import { observable } from 'mobx';

export class Engineer extends Model {
    static backendResourceName = 'engineer'

    @observable id = null;
    @observable rank = null;
    @observable createdAt = null;
    @observable firstName = null;
    @observable lastName = null;
    @observable isActive = false;

}

export class EngineerStore extends Store{
    Model = Engineer
    static backendResourceName = 'engineer'
}