import PropTypes from 'prop-types';
import React, {Component} from 'react';
import View from 'spider/store/View';
import {Route, Switch, Redirect} from 'react-router-dom';
import Load from './Loadable';

const UserOverview = Load(() => import('../screen/User/Overview'));
const UserEdit = Load(() => import('../screen/User/Edit'));
const GlobalValueOverview = Load(() => import('../screen/GlobalValue/Overview'));
const GlobalValueEdit = Load(() => import('../screen/GlobalValue/Edit'));
const GlobalFileOverview = Load(() => import('../screen/GlobalFile/Overview'));
const GlobalFileEdit = Load(() => import('../screen/GlobalFile/Edit'));
const AccessLogEntryOverview = Load(() => import('../screen/AccessLogEntry/Overview'));
const EmailLogOverview = Load(() => import('../screen/User/EmailLog'));

const LoginForgot = Load(() => import('spider/semantic-ui/LoginForgot'));
const ResetPassword = Load(() => import('../screen/ResetPassword'));
const AccountDetails = Load(() => import('../screen/AccountDetails'));
const Changelog = Load(() => import('../screen/Changelog'));

const EngineerAdminOverview = Load(() => import('../screen/Engineer/AdminOverview'))
const EngineerAccessAdminOverview = Load(() => import('../screen/EngineerMachineAccess/AdminOverview'))
const AccessOverviewScreen = Load(() => import('../screen/Engineer/MachineAccessOverview'))
const MachineAdminOverview = Load(() => import('../screen/Machine/AdminOverview'))
const MachineEdit = Load(() => import('../screen/Machine/Edit'));
const EngineerAccessEdit= Load(() => import('../screen/EngineerMachineAccess/Edit'));



const NotFound = Load(() => import('../container/NotFound'));

export default class Router extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(View).isRequired,
    };

    // react-router is a bit too verbose so I made a shorthand
    route = Screen => {
        return rProps => <Screen {...rProps} viewStore={this.props.store}/>;
    };

    redirectFromHome = () => {
        const {currentUser} = this.props.store;
        let uri = currentUser.isSuperuser ? (
            '/assets/user/overview'
        ) : (
            '/account/details'
        );

        return <Redirect to={uri}/>;
    };

    render() {
        return (
            <Switch>
                <Route path="/" exact render={this.redirectFromHome}/>

                <Route path="/assets/user/overview" render={this.route(UserOverview)}/>
                <Route path="/assets/user/:id/edit" render={this.route(UserEdit)}/>
                <Route path="/assets/user/add" render={this.route(UserEdit)}/>
                <Route path="/assets/user/email-log/overview" render={this.route(EmailLogOverview)}/>

                <Route path="/assets/global-value/overview" render={this.route(GlobalValueOverview)}/>
                <Route path="/assets/global-value/:id/edit" render={this.route(GlobalValueEdit)}/>

                <Route path="/assets/global-file/overview" render={this.route(GlobalFileOverview)}/>
                <Route path="/assets/global-file/:id/edit" render={this.route(GlobalFileEdit)}/>

                <Route path="/audit/access-log/overview" render={this.route(AccessLogEntryOverview)}/>
                <Route path="/engineer/overview" render={this.route(EngineerAdminOverview)}/>
                <Route path="/engineer/:id/access" render={this.route(AccessOverviewScreen)}/>
                <Route path="/engineeraccess/overview" render={this.route(EngineerAccessAdminOverview)}/>
                <Route path="/engineeraccess/:id/edit" render={this.route(EngineerAccessEdit)}/>
                <Route path="/engineeraccess/add" render={this.route(EngineerAccessEdit)}/>
                <Route path="/machine/overview" render={this.route(MachineAdminOverview)}/>
                <Route path="/machine/:id/edit" render={this.route(MachineEdit)}/>
                <Route path="/machine/add" render={this.route(MachineEdit)}/>

                <Route path="/account/details" render={this.route(AccountDetails)}/>
                <Route path="/account/changelog" render={this.route(Changelog)}/>
                <Route path="/login/forgot" render={this.route(LoginForgot)}/>
                <Route path="/user/:id/reset-password/:token" render={this.route(ResetPassword)}/>

                <Route render={this.route(NotFound)}/>
            </Switch>
        );
    }
}
