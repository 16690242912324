/**
 * Similar to src/Button.js, but based on Semantic-UI
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Store } from 'mobx-spine';
import { Button as BaseButton, Icon, Popup, Dropdown, Responsive, List } from 'semantic-ui-react';
import styled from 'styled-components';
import { t } from 'i18n';
import { omit, pick } from 'lodash';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';

const SU_COLORS = ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'];
function customColor(props) {
    if (props.color && !(props.color in SU_COLORS)) {
        return `color: ${props.color};`;
    }

    return '';
}

const StyledIcon = styled(({ color, ...rest }) => {
    if (!SU_COLORS.includes(color)) {
        return <Icon style={{ color }} {...rest} />;
    } else {
        return <Icon color={color} {...rest} />;
    }
})`
    cursor: pointer;
    margin: 4px !important;
    line-height: normal;
`;

const linkCss = `
    color: inherit;
    text-decoration: none;
    &:visited {
        color: inherit;
    }
    &:hover {
        color: inherit;
    }
    &:active {
        color: inherit;
    }

    ${props => customColor(props)}
`;

const StyledA = styled.a`
    ${linkCss}
`;

const StyledLink = styled(Link)`
    ${linkCss}
`;

export { StyledLink as Link };
export { StyledIcon as Icon };

const StyledPopup = styled(Popup)`
    /* Makes buttons fluid in the popup on mobile */
    .ui.button {
        width: 100%;
    }
`;

export class ResponsiveContainer extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    render() {
        const { children } = this.props;

        return (
            <React.Fragment>
                <Responsive maxWidth={Responsive.onlyComputer.minWidth}>
                    <StyledPopup trigger={<BaseButton primary icon="ellipsis vertical" />} on="click">
                        <List divided relaxed>
                            {children.map
                                ? children.map(b => <List.Item>{b}</List.Item>)
                                : children
                            }
                        </List>
                    </StyledPopup>
                </Responsive>
                <Responsive minWidth={Responsive.onlyComputer.minWidth} style={{ display: 'flex' }}>
                    {children}
                </Responsive>
            </React.Fragment>
        );
    }
}

/**
 * Use Semantic-UI icons as buttons. It also support simple titles which will
 * turn into popups.
 */
export class IconButton extends Component {
    static propTypes = {
        /** Renders a Popup with title content. */
        title: PropTypes.string,
        /** Suddenly render as an external link. */
        href: PropTypes.string,
        /** Suddenly render as an internal link. */
        to: PropTypes.string,

        /** Semantic only supports basic colors, but here we add support for custom colors. */
        color: PropTypes.string,
        name: PropTypes.string.isRequired,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
    };

    render() {
        const { title, to, href, loading, name, disabled, onClick, ...props } = this.props;
        let buttonIcon = <StyledIcon disabled={disabled} onClick={disabled ? null : onClick} {...props} loading={loading} name={loading ? 'circle notch' : name} />;

        if (href) {
            buttonIcon = <StyledA href={href}>{buttonIcon}</StyledA>;
        }

        if (to) {
            buttonIcon = <StyledLink to={to}>{buttonIcon}</StyledLink>
        }

        if (title) {
            return <Popup style={{ marginLeft: '-0.75em' }} trigger={buttonIcon} content={title} />
        }

        return buttonIcon;
    }
}

export class Button extends BaseButton {
    static defaultProps = {
        type: 'button',
        labelPosition: 'left',
    }
}

export class SyncButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'sync',
        labelPosition: 'left',
        content: t('form.syncButton'),
    }
}

export class CancelButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'cancel',
        labelPosition: 'left',
        content: t('form.cancelButton'),
    }
}

export class DownloadButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'download',
        labelPosition: 'left',
        content: t('form.downloadButton'),
    }
}

export class SendButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'send',
        labelPosition: 'left',
        content: t('form.sendButton'),
    }
}

export class SaveButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'save',
        labelPosition: 'left',
        content: t('form.saveButton'),
    }
}

export class CopyButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'copy',
        labelPosition: 'left',
        content: t('form.copyButton'),
    }
}

export class AddButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'add circle',
        labelPosition: 'left',
        content: t('form.addButton'),
    }
}

export class EditButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'edit',
        labelPosition: 'left',
        content: t('form.editButton'),
    }
}

export class ApplyButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'check',
        labelPosition: 'left',
        content: t('form.applyButton'),
    }
}

export class DeleteButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'trash',
        labelPosition: 'left',
        content: t('form.deleteButton'),
        color: 'red',
    }
}

export class RestoreButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'undo',
        labelPosition: 'left',
        content: t('form.restoreButton'),
    }
}

const MyDropzone = styled(Dropzone)`
    position: relative;
    display: inline;
    cursor: pointer;
`;

@observer
export class DroppableButton extends Component {
    static propTypes = {
        onDrop: PropTypes.func.isRequired,
        title: PropTypes.string,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    @observable droppable = false;

    onDrop = (...args) => {
        const { onDrop } = this.props;

        this.droppable = false;

        onDrop(...args);
    }

    render() {
        const propsIconButton = ['fitted', 'title', 'loading'];
        const { title, loading, disabled, ...props } = this.props;

        if (disabled) {
            return <IconButton name="upload" color='grey' {...pick(props, propsIconButton)} />;
        }

        return (
            <MyDropzone
                activeStyle={{ position: 'relative', display: 'inline', cursor: 'pointer' }}
                {...omit(props, propsIconButton)}
                onDragOver={() => this.droppable = true}
                onDragLeave={() => this.droppable = false}
                onDrop={this.onDrop}
            >
                <IconButton title={title} name="upload" color={this.droppable ? 'red' : undefined} loading={loading} {...pick(props, propsIconButton)} />
            </MyDropzone>
        );
    }
}

@observer
export class BulkActionsButton extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(Store).isRequired,
        actions: PropTypes.array.isRequired,
        label: PropTypes.func,
        className: PropTypes.string,
    }

    @observable isBulkActionLoading = false;

    afterBulkAction = () => {
        this.isBulkActionLoading = false;
    }

    renderBulkAction = ({ icon, key, filter, action, label }) => {
        const { store, label: propLabel } = this.props;


        if (!filter) {
            filter = () => true;
        }

        const count = store.filter(filter).length;
        let actualLabel = typeof propLabel === 'function' ? propLabel({ key, count }) : propLabel;

        if (label) {
            actualLabel = typeof label === 'function' ? label({ key, count }) : label;
        }

        return (
            <Dropdown.Item
                key={key}
                icon={icon}
                text={actualLabel}
                onClick={() => {
                    const tempStore = new store.constructor();
                    tempStore.models = store.filter(filter);

                    this.isBulkActionLoading = true;
                    action(tempStore).catch(() => {}).then(this.afterBulkAction);
                    store.clear();
                }}
                disabled={count === 0}
            />
        );
    }

    render() {
        const { store, actions, className, ...rest } = this.props;

        return (
            <Dropdown compact floating button labeled {...rest}
                loading={this.isBulkActionLoading}
                className={`icon primary ${className}`}
                text={t('order.edit.bulkActionButton.choose', {
                    count: store.length,
                })}
            >
                <Dropdown.Menu>
                    {actions.map(this.renderBulkAction)}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
