import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable, set, computed } from 'mobx';
import { Model, Store } from 'mobx-spine';
import { Link } from 'react-router-dom';
import { Dimmer, Loader, Form, Table, Icon, Popup, Button, Menu, Input } from 'semantic-ui-react';
import moment from 'moment';
import { ACTION_DELAY, snakeToCamel, camelToSnake, BOOL_OPTIONS } from '../../helpers';
import bindUrlParams, { encodeUrlByPathname } from '../../helpers/bindUrlParams';
import { Body, ContentContainer, Content, Toolbar, RadioButtons } from 're-cy-cle';
import RightDivider from '../../component/RightDivider';
import styled from 'styled-components';
import { ResponsiveContainer, IconButton } from '../Button';
import HeaderRight from '../HeaderRight';
import axios from 'axios';
import { result, debounce } from 'lodash';
import { Helmet } from 'react-helmet';
import { t } from 'i18n';
import { TargetTextInput, TargetDatePicker, TargetDateRangePicker, TargetMultiPick, TargetRadioButtons } from '../Target';
import { Toggle } from '../../component/FloatingSidebar';
import { Scrollbars } from 'react-custom-scrollbars';
import MyFilters from '../../component/MyFilters';

const StyledToggle = styled(Toggle)`
    > * {
       top: ${({ offset }) => offset};
    }
`;

const SidebarContent = styled.div`
    padding: 2rem;
`;

const TARGETS = {
    text: TargetTextInput,
    date: TargetDatePicker,
    dateRange: TargetDateRangePicker,
    multiPick: TargetMultiPick,
    search: TargetTextInput,
    radioButtons: TargetRadioButtons,
    bool: TargetRadioButtons,
};
const DEBOUNCE = {
    text: true,
    search: true,
};
const DEFAULT_PROPS = {
    dateRange: {
        startPlaceholder: t('form.startDate'),
        endPlaceholder: t('form.endDate'),
    },
    search: {
        label: t('form.search'),
        name: 'search',
    },
    bool: {
        options: BOOL_OPTIONS,
    },
};

const SMALL_STYLE_FIRST = {
    paddingRight: 2,
    textAlign: 'center',
};

const SMALL_STYLE = {
    paddingLeft: 2,
    paddingRight: 2,
    textAlign: 'center',
};

const SMALL_STYLE_LAST = {
    paddingLeft: 2,
    paddingRight: 2,
    textAlign: 'center',
};

const ModalContentContainer = styled(ContentContainer)`
    position: relative;
    height: 100%;
    ${StyledToggle} > * {
        z-index: 1000;
        top: 0px;
        right: ${({ index }) => index * 3 + 1}rem;
        background-color: ${({ isActive }) => isActive ? '#e0e0e0' : '#f8f8f8'} !important;
    }
    ${StyledToggle} > i.icon {
        margin: 0 !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 0.9rem !important;
        border-bottom-right-radius: 0.9rem !important;
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-top-width: 0;
        color: rgba(0, 0, 0, 0.87) !important;
    }
`;

// Copy pasta from re-cy-cle because the re-cy-cle Sidebar does not work with
// styled-components and these are not exported
const StyledAside = styled.aside`
    ${props => {
        const width = !props.show ? 0 : props.medium ? 450 : props.small ? 300 : 350;
        return `
            flex: 0 0 auto;
            width: ${width}px;
            background: ${props.theme.lightColor};
            &.slide-right-enter,
            &.slide-right-leave.slide-right-leave-active {
                margin-right: -${width}px;
            }
            &.slide-left-enter,
            &.slide-left-leave.slide-left-leave-active {
                margin-left: -${width}px;
            }
            &.slide-right-leave,
            &.slide-right-enter.slide-right-enter-active,
            &.slide-left-leave,
            &.slide-left-enter.slide-left-enter-active {
                margin-right: 0;
            }
            &.slide-right-enter-active,
            &.slide-right-leave-active,
            &.slide-left-enter-active,
            &.slide-left-leave-active {
                transition: margin 300ms ease;
            }
            transition: width 300ms ease;
        `;
    }};
`;

export class Sidebar extends Component {
    static propTypes = {
        children: PropTypes.node,
        medium: PropTypes.bool,
        small: PropTypes.bool,
        show: PropTypes.bool,
    };

    static defaultProps = {
        show: true,
    };

    render() {
        const { children, ...props } = this.props;

        return (
            <StyledAside {...props}>
                <Scrollbars>
                    <SidebarContent>{children}</SidebarContent>
                </Scrollbars>
            </StyledAside>
        );
    }
}

export const StyledTableHeaderCell = styled(Table.HeaderCell)`
    ${({ onClick }) => onClick ? `
        cursor: pointer !important;
        user-select: none;
    ` : ``}
    white-space: nowrap;
    text-align: left !important;
    position: sticky;
    top: 0;
    z-index: 1;
`;

export const SortIconGroup = styled(Icon.Group)`
    margin-right: 0.5rem;
`;

export const SortIcon = styled(Icon)`
    opacity: ${({ active }) => active ? 0.9 : 0.2} !important;
    margin-bottom: 0.1rem !important;
`;

export function getModelName(model) {
    return snakeToCamel(model.backendResourceName.replace(/\//g, '_'));
}

export const ItemButton = ({ icon, label, ...props }) => {
    const key = props['key'];
    delete props['key'];

    const button = (
        <Button primary size="small" icon={icon} {...props}/>
    );

    if (label) {
        return (
            <Popup key={key} content={label} trigger={button} />
        );
    }

    return button;
};

const StyledTableRow = styled(Table.Row)`
    ${props => props.deleted && `
        opacity: 0.5;
    `}
`;

export const ToolbarButton = ({ ...props }) => (
    <Button primary compact labelPosition="left" {...props} />
);

const PageInput = styled(Input)`
    width: 80px;
    margin-right: 5px;
`;

export const FullDimmable = styled(Dimmer.Dimmable)`
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex: 1;
    > .ui.dimmer {
        pointer-events: none;
    }
`;

@observer
export class PaginationControls extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        onFetch: PropTypes.func,
        afterFetch: PropTypes.func,
    };

    @observable
    newPage = 1;

    handleNewPageOpen() {
        this.newPage = this.store.currentPage;
    }

    handleNewPageChange(e, { value }) {
        this.newPage = parseInt(value);
    }

    handleNewPageApply() {
        const { afterFetch } = this.props;

        this.onFetch(this.props.store.setPage(this.newPage)).then(afterFetch);
    }

    onFetch(p) {
        const { onFetch } = this.props;

        onFetch && onFetch(p);

        return p;
    }

    render() {
        const { store, afterFetch } = this.props;

        const currentPage = (
            <Menu.Item as="a">
                {store.currentPage}/{store.totalPages}
            </Menu.Item>
        );

        const currentPageWithPopUp = (
            <Popup
                hoverable
                trigger={currentPage}
            >
                <Popup.Content>
                <PageInput
                    size="mini"
                    value={this.newPage || ''}
                    onChange={this.handleNewPageChange.bind(this)}
                />
                <Button primary size="mini" icon="arrow right" onClick={this.handleNewPageApply.bind(this)} />
                </Popup.Content>
            </Popup>
        );

        return (
            <Menu pagination size="mini">

                <Menu.Item icon
                    as="a"
                    onClick= {store.hasPreviousPage && ( () => this.onFetch(store.getPreviousPage()).then(afterFetch) )}
                >

                    <Icon name="chevron left" />
                </Menu.Item>

                {currentPageWithPopUp}

                <Menu.Item icon
                    as="a"
                    onClick={store.hasNextPage && ( () => this.onFetch(store.getNextPage()).then(afterFetch) )}
                >
                    <Icon name="chevron right" />
                </Menu.Item>

            </Menu>
        );
    }
}

@observer
export class TableHeader extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(Store).isRequired,
        setting: PropTypes.object.isRequired,
    };

    constructor(...args) {
        super(...args);
        this.onSort = this.onSort.bind(this);
    }

    getOrderBy() {
        const { store } = this.props;
        if (store.params.order_by) {
            return store.params.order_by.split(',');
        } else {
            return [];
        }
    }

    setOrderBy(value) {
        const { store } = this.props;
        if (value.length === 0) {
            delete store.params.order_by;
        } else {
            store.params.order_by = value.join(',');
        }
    }

    @computed get orderBy() {
        return this.getOrderBy();
    }

    set orderBy(value) {
        return this.setOrderBy(value);
    }

    @computed get sortKey() {
        const { setting } = this.props;
        let { sortKey } = setting;

        if (typeof sortKey === 'function') {
            sortKey = sortKey();
        }
        if (typeof sortKey === 'string') {
            sortKey = [sortKey];
        }

        return sortKey;
    }

    @computed get sortState() {
        if (!this.sortKey) {
            return null;
        }

        if (this.orderBy.includes(this.sortKey[0])) {
            return 'asc';
        } else if (this.orderBy.includes(`-${this.sortKey[0]}`)) {
            return 'desc';
        } else {
            return null;
        }
    }

    onSort() {
        const { store } = this.props;

        switch (this.sortState) {
            case null: // To 'asc'
                this.orderBy = [...this.sortKey, ...this.orderBy];
                break;
            case 'asc': // To 'desc'
                this.orderBy = [
                    ...this.sortKey.map((key) => `-${key}`),
                    ...this.orderBy.filter((key) => !this.sortKey.includes(key)),
                ];
                break;
            case 'desc': // To null
                const descSortKeys = this.sortKey.map((key) => `-${key}`);
                this.orderBy = this.orderBy.filter((key) => !descSortKeys.includes(key));
                break;
            default:
                throw new Error('Invalid sortState');
        }

        if (store.updateUrlParams) {
            store.updateUrlParams();
        }

        this.fetch();
    }

    render() {
        const { setting } = this.props;
        const { label, collapsing, props } = setting;

        return (
            <StyledTableHeaderCell
                data-test-sort={this.sortKey ? this.sortKey : undefined}
                onClick={this.sortKey ? this.onSort : undefined}
                collapsing={collapsing}
                {...props}
            >
                {this.sortKey && (
                    <SortIconGroup>
                        {/* First Icon is positioned weirdly for some reason so dummy icon */}
                        <Icon />
                        <SortIcon
                            name="sort ascending"
                            active={this.sortState === 'asc'}
                        />
                        <SortIcon
                            name="sort descending"
                            active={this.sortState === 'desc'}
                        />
                    </SortIconGroup>
                )}
                {label}
            </StyledTableHeaderCell>
        );
    }

    fetch() {
        const { store } = this.props;

        if (this.cancelRequest) {
            this.cancelRequest();
        }

        return store.fetch({
            cancelToken: new axios.CancelToken(c => {
                this.cancelRequest = c;
            }),
        });
    }

    debouncedFetch = debounce(this.fetch, ACTION_DELAY);
}


@observer
export default class AdminOverview extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        viewStore: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object,
        location: PropTypes.object,
    };

    TableHeader = TableHeader;

    DATE_FORMAT = 'DD-MM-YYYY';

    header = '';
    HeaderRight = HeaderRight
    title = '';
    tabTitlePrefix = null;

    myFilterKey = null;
    myFilterBlacklist = []
    myFilterWhitelist = undefined;
    myFilterProps = {};

    /**
     * Sync url with store params, so when refreshing, the store will recieve
     * the same params and remembers filter settings.
     */
    bindUrlParams = true;

    /**
     * Directly fetch store when mounted. Disable for performance. Can be a
     * function.
     *
     */
    fetchOnMount() {
        return !this.myFilterKey;
    }

    /**
     * Sometimes you want to show an initial message, before the first fetch occures.
     */
    @observable initialFetchOccured = false;

    @observable showSidebar = !localStorage.getItem(`hide-sidebar-${this.myFilterKey}`);

    @observable meta = {};

    /**
     * Add buttons per row. Example:
     * buttons: [
     *    (model) => <Button>{model.id}</Button>
     * ];
     */
    itemButtonProps = {};
    buttons = [];
    toolbar = [];

    /**
     * Render (multiple) sidebars. Only 1 sidebar can be active at the same time.
     * Example:
     *
     * sidebars = [
     * {
     *     trigger: props => <IconButton name="search" {...props} />,
     *     content: () => (
     *         <Form>
     *             {this.filters.map(this.renderFilter.bind(this))}
     *         </Form>
     *     )
     * }, {
     *     trigger: props => <IconButton name="search" {...props} />,
     *     content: () => (
     *         <Form>
     *             {this.filters.map(this.renderFilter.bind(this))}
     *         </Form>
     *     )
     * }];
     */
    sidebars = [];
    @observable sidebarActiveIndex = null;
    sidebarsToggleTopOffset = '53px';

    @computed get next() {
        if (this.props.location) {
        return encodeUrlByPathname(this.store, this.props.location.pathname);
    }

        return null;
    }

    @computed get finalButtons() {
        let buttons = this.buttons;

        if (typeof buttons === 'function') {
            buttons = buttons();
        }

        return buttons ? buttons.slice() : [];
    }

    componentDidMount() {
        if (this.bindUrlParams) {
            this.clearUrlBinding = bindUrlParams({
                store: this.store,
                defaultParams: this.getDefaultParams(),
            });
        }

        if (result(this, 'fetchOnMount')) {
            this.fetch();
        }
    }

    setDimmerMargin() {
        console.log('DIMMER MARGIN');
    }

    componentWillUnmount() {
        if (this.clearUrlBinding) {
            this.clearUrlBinding();
        }
    }

    debouncedFetch = debounce(this.fetch.bind(this), ACTION_DELAY);
    fetch() {
        this.initialFetchOccured = true;

        if (this.cancelRequest) {
            this.cancelRequest();
        }

        return (
            this.store.fetch({
                cancelToken: new axios.CancelToken(c => {
                    this.cancelRequest = c;
                }),
            })
            .then((response) => {
                this.cancelToken = undefined;
                set(this.meta, response.meta);
                return response;
            })
            .then(this.afterFetch)
        );
    }

    afterFetch() {}

    getDefaultParams() {
        return this.params;
    }

    /**
     * Backend handles deleted differently than other filters.
     */
    handleDeletedChange = (name, value) => {
        const store = this.store;

        if (value === 'true') {
            store.params[name] = 'true';
        } else {
            delete store.params[name];
        }

        // Mobx only supports already existing keys when you started @observable.
        // In this case we add / delete a key, so mobx can't properly observe
        // changes. In v4 of mobx this is "fixed" by using set / remove from
        // mobx, but we are alas still stuck in 3.1.2...
        this.forceUpdate();
        store.updateUrlParams();
        store.setPage().then(response => {
            set(this.meta, response.meta);
        });
    }

    getSettings() {
        return this.settings;
    }

    @computed get mappedSettings() {
        return this.mapSettings(this.getSettings());
    }

    attrSetting(setting) {
        const path = setting.attr.split('.');
        const field = path.pop();

        let model = this.store.Model;
        let label, sortKey;
        if (path.length > 0) {
            let prevModel = null;
            sortKey = '';
            for (const field of path) {
                if (sortKey !== '') {
                    sortKey += '.';
                }
                sortKey += camelToSnake(field);
                prevModel = model;
                model = model.prototype.relations()[field];
                if (!(model.prototype instanceof Model)) {
                    throw new Error('non-model in path');
                }
            }
            label = t(`${getModelName(prevModel)}.field.${path[path.length - 1]}.label`);
            sortKey += '.' + camelToSnake(field);
        } else {
            label = t(`${getModelName(model)}.field.${field}.label`);
            sortKey = camelToSnake(field);
        }

        return {
            ...setting,
            label: setting.label || label,
            sortKey: setting.sortKey || sortKey,
            attr: (obj) => {
                for (const field of path) {
                    obj = obj[field];
                }
                return obj[field];
            },
        };
    }

    handleSmall(setting, i) {
        if (setting.small) {
            return {
                ...setting,
                collapsing: true,
                props: { style: (
                    i === 0
                    ? SMALL_STYLE_FIRST
                    : i === this.getSettings().length - 1
                    ? SMALL_STYLE_LAST
                    : SMALL_STYLE
                ) },
            };
        } else if (setting.centered) {
            return {
                ...setting,
                props: { style: { textAlign: 'center' } },
            };
        } else {
            return setting;
        }
    }

    /**
     * Generate headings from settings. For now it auto creates labels based
     * on attr.
     */
    mapSettings(settings) {
        return settings.map((setting) => {
            // Auto add label if it's missings.
            if (typeof setting === 'string') {
                if (setting === '') {
                    setting = {};
                } else {
                    setting = { attr: setting };
                }
            }

            if (
                typeof setting === 'object' &&
                typeof setting.attr === 'string'
            ) {
                setting = this.attrSetting(setting);
            }

            return this.handleSmall(setting);
        });
    }

    generateSearchParams() {
        if (this.next) {
            return `?next=${this.next}`;
        }

        return '';
    }

    renderTitle() {
        return this.title && (
            <this.HeaderRight as="h1" content={this.title}>
                {this.renderTitleRight()}
            </this.HeaderRight>
        );
    }

    renderTitleRight() {
        return this.myFilterKey && (
            <MyFilters
                store={this.store}
                view={this.myFilterKey}
                blacklist={this.myFilterBlacklist}
                whitelist={this.myFilterWhitelist}
                defaultParams={this.getDefaultParams()}
                fromUrl={this.bindUrlParams}
                {...this.myFilterProps}
            />
        );
    }

    renderTabTitle() {
        if (this.title && this.tabTitlePrefix) {
            return (
                <Helmet>
                    <title>{this.tabTitlePrefix} {this.title}</title>
                </Helmet>
            );
        }
    }

    renderContent() {
        return (
            <React.Fragment>
                <FullDimmable>
                    <Dimmer inverted active={this.store.isLoading}>
                        <Loader inverted size="big" />
                    </Dimmer>
                    <Content>
                        {this.renderTitle()}
                        {this.renderOverviewTable.call(this)}
                    </Content>
                </FullDimmable>
                {/* How does the modal work? Should refactor to renderSidebars? */}
                {this.renderSidebar({ small: this.modal })}
                {this.renderSidebars()}
            </React.Fragment>
        );
    }

    renderBody() {
        // Needed because calling super.render() will cause problems because of
        // how the @observer decorator changes the render method
        const content = this.renderContent();

        if (this.modal) {
            return (
                <ModalContentContainer>
                    {content}
                </ModalContentContainer>
            );
        }

        return (
            <Body>
                {this.renderTabTitle()}
                <ContentContainer>
                    {content}
                </ContentContainer>
                {this.renderToolbar.call(this)}
            </Body>
        );
    }

    render() {
        return this.renderBody();
    }

    renderDeletedFilter() {
        const params = this.store.params ? this.store.params : {};

        return (
            <Form.Field>
                <label>{t('common.filter.deleted')}</label>
                <RadioButtons
                    name="deleted"
                    onChange={this.handleDeletedChange}
                    value={params.deleted === 'true' ? 'true' : 'false'}
                    options={[
                        { value: 'false', label: t('form.no') },
                        { value: 'true', label: t('form.yes') },
                    ]}
                />
            </Form.Field>
        );
    }

    renderOverviewTable() {
        return (
            <Table {...this.tableProps()}>
                <Table.Header>
                    <Table.Row>
                        {this.mappedSettings.map(this.renderHeader.bind(this))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.store.map(this.renderRow.bind(this))}
                </Table.Body>
            </Table>
        );
    }

    renderHeader(setting, i) {
        return (
            <this.TableHeader key={i} setting={setting} store={this.store} />
        );
    }

    tableProps() {
        return {};
    }

    rowProps(item, i) {
        return {};
    }

    cellProps(item, setting, i) {
        let props = setting.cellProps || {};
        if (typeof props === 'function') {
            props = props(item, i);
        }
        if (setting.small) {
            props.style = Object.assign(props.style || {}, (
                i === 0
                ? SMALL_STYLE_FIRST
                : i === this.getSettings().length - 1
                ? SMALL_STYLE_LAST
                : SMALL_STYLE
            ));
        }
        if (setting.centered) {
            props.style = Object.assign(props.style || {}, { textAlign: 'center' });
        }

        return props;
    }

    renderRow(item, i) {
        return (
            <StyledTableRow key={i} deleted={item.deleted} {...this.rowProps(item, i)}>
                {this.mappedSettings.map((setting, i) => this.renderCell.bind(this)(
                    item, setting, i,
                ))}
                {this.finalButtons.length > 0 && (
                    <Table.Cell collapsing singleLine textAlign="right">
                        {this.finalButtons.map((button, i) => this.renderButton.bind(this)(
                            item, button, i + this.mappedSettings.length,
                        ))}
                    </Table.Cell>
                )}
            </StyledTableRow>
        );
    }

    renderCell(item, setting, i) {
        let val = '';
        if (setting.attr !== undefined) {
            if (typeof setting.attr === 'function') {
                val = setting.attr(item);
            } else {
                val = item[setting.attr];
            }
            // Format moments
            if (moment.isMoment(val)) {
                val = val.format(this.DATE_FORMAT);
            }
            // Format booleans
            if (typeof val === 'boolean') {
                val = val ? <Icon name="check" /> : '';
            }

            return (
                <Table.Cell key={i} {...this.cellProps(item, setting, i)}>
                    {val}
                </Table.Cell>
            );
        }
        return null;
    }

    removeItem(item) {
        if (window.confirm(t('form.deleteConfirmation'))) {
            return item.delete();
        } else {
            return Promise.reject();
        }
    }

    restoreItem(item) {
        if (window.confirm(t('form.restoreConfirmation'))) {
            return item.restore().then(() => item.deleted = false);
        } else {
            return Promise.reject();
        }
    }

    renderButton(item, button, i) {
        if (typeof button === 'function') {
            return button(item, i);
        }

        let type = button.type;

        if (typeof type === 'function') {
            type = type(item, i);
        }

        switch (type) {
            case 'custom':
                return button.callback(item, i);
            case 'view':
                return (
                    <ItemButton
                        key={i}
                        icon="eye" label={button.viewLabel || t('tooltips.view')}
                        as={Link} to={button.to(item)}
                        {...this.itemButtonProps}
                    />
                );
            case 'edit':
                return (item.deleted) ? (
                    <ItemButton
                        data-test-view-button={item.id}
                        key={i}
                        icon="eye" label={button.viewLabel || t('tooltips.view')}
                        as={Link} to={`${button.to(item)}${this.generateSearchParams()}`}
                        {...this.itemButtonProps}
                    />
                ) : (
                    <ItemButton
                        data-test-edit-button={item.id}
                        key={i}
                        icon="edit" label={button.editLabel || t('tooltips.edit')}
                        as={Link} to={`${button.to(item)}${this.generateSearchParams()}`}
                        {...this.itemButtonProps}
                    />
                );
            case 'hardDelete':
                return (
                    <ItemButton
                        key={i}
                        icon="delete" label={button.deleteLabel || t('tooltips.delete')}
                        onClick={() => this.removeItem(item)}
                        {...this.itemButtonProps}
                    />
                );
            case 'delete':
                return (item.deleted) ? (
                    <ItemButton
                        key={i}
                        data-test-redo-button={item.id}
                        icon="redo" label={button.restoreLabel || t('tooltips.restore')}
                        onClick={() => this.restoreItem(item)}
                        {...this.itemButtonProps}
                    />
                ) : (
                    <ItemButton
                        key={i}
                        data-test-delete-button={item.id}
                        icon="delete" label={button.deleteLabel || t('tooltips.delete')}
                        onClick={() => this.removeItem(item)}
                        {...this.itemButtonProps}
                    />
                );
            case 'download':
                return (
                    <ItemButton
                        key={i}
                        icon="download" label={button.label}
                        as="a" href={button.href(item)}
                        {...this.itemButtonProps}
                    />
                );
            default:
                return null;
        }
    }

    toggleSidebar() {
        this.showSidebar = !this.showSidebar;

        if (this.showSidebar) {
            localStorage.removeItem(`hide-sidebar-${this.myFilterKey}`);
        } else {
            localStorage.setItem(`hide-sidebar-${this.myFilterKey}`, true);
        }
    }

    toggleSidebars(index) {
        if (this.sidebarActiveIndex === index) {
            this.sidebarActiveIndex = null;
        } else {
            this.sidebarActiveIndex = index;
        }

        // Untested, might have some issues with the new this.sidebars functionality.
        if (this.sidebarActiveIndex !== null) {
            localStorage.removeItem(`hide-sidebar-${this.myFilterKey}`);
        } else {
            localStorage.setItem(`hide-sidebar-${this.myFilterKey}`, true);
        }
    }

    renderSidebarTrigger(props) {
        return <IconButton name="search" {...props} />;
    }

    renderSidebar(props = {}) {
        if (!this.filters) {
            return null;
        }

        return (
            <React.Fragment>
                <StyledToggle
                    data-test-floating-sidebar-toggle={0}
                    index={0}
                    isActive={this.showSidebar}
                    offset={this.sidebarsToggleTopOffset}
                >
                    {this.renderSidebarTrigger({ onClick: this.toggleSidebar.bind(this) })}
                </StyledToggle>
                <Sidebar show={this.showSidebar} {...props}>
                    {this.renderSidebarContent()}
                </Sidebar>
            </React.Fragment>
        );
    }

    renderSidebars() {
        if (!this.sidebars) {
            return null;
        }

        return (
            <React.Fragment>
                {this.sidebars.map(({ trigger }, index) => (
                    <StyledToggle
                        data-test-floating-sidebar-toggle={index}
                        index={index}
                        isActive={this.sidebarActiveIndex === index}
                        offset={this.sidebarsToggleTopOffset}
                    >
                        {trigger({ onClick: () => this.toggleSidebars(index) })}
                    </StyledToggle>
                ))}
                <Sidebar data-test-sidebar show={this.sidebarActiveIndex !== null}>
                    {this.sidebarActiveIndex !== null ? this.sidebars[this.sidebarActiveIndex].content() : null}
                </Sidebar>
            </React.Fragment>
        );
    }

    renderSidebarContent() {
        return (
            <Form>
                {this.filters.map(this.renderFilter.bind(this))}
            </Form>
        );
    }

    renderFilter(filter, i) {
        if (typeof filter === 'function') {
            filter = filter();
        }

        const { type, targetProps = {}, ...props } = filter;

        if (type === 'custom') {
            const { callback, ...args } = props;
            return callback(args);
        }

        const Target = TARGETS[type];
        const afterChange = (
            DEBOUNCE[type]
            ? this.debouncedFetch
            : this.fetch.bind(this)
        );
        const defaultProps = DEFAULT_PROPS[type] || {};

        return (
            <Target
                key={i}
                target={this.store}
                afterChange={afterChange}
                autoComplete="off"
                {...defaultProps}
                {...props}
                {...targetProps}
            />
        );
    }

    renderPaginationControls() {
        return (
            <PaginationControls store={this.store} />
        );
    }

    renderToolbar() {
        const { toolbar } = this;
        const toolbarButtons = this.renderToolbarButtons();

        if (!toolbar) {
            return null;
        }

        return (
            <Toolbar>
                {this.renderPaginationControls()}
                <RightDivider />
                {toolbarButtons && (
                    <ResponsiveContainer>
                        {toolbarButtons}
                    </ResponsiveContainer>
                )}
            </Toolbar>
        );
    }

    renderToolbarButtons() {
        const { toolbar } = this;

        return toolbar.map(this.renderToolbarItem.bind(this));
    }

    renderToolbarItem(item, i) {
        if (typeof item === 'function') {
            return item(i);
        }

        switch (item.type) {
            case 'custom':
                return item.callback();
            case 'add':
                if (item.label === undefined) {
                    item.label = t('form.addButton');
                }
                return (
                    <ToolbarButton
                        key={i}
                        icon="add" content={item.label}
                        as={Link}
                        to={`${item.to}${this.generateSearchParams()}`}
                    />
                );
            case 'view':
                return (
                    <ToolbarButton
                        key={i}
                        icon="view" content={item.label}
                        as={Link}
                        to={`${item.to}${this.generateSearchParams()}`}
                    />
                );
            case 'download':
                return (
                    <ToolbarButton
                        key={i}
                        icon="download" content={item.label}
                        as={Link}
                        to={`${item.to}${this.generateSearchParams()}`}
                    />
                );
            default:
                return null;
        }
    }
}
