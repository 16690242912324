import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from './container/App';
import ViewStore from 'spider/store/View';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { theme } from './styles.js';
import { t } from './i18n';
import i18next from 'i18next';
import { BrowserRouter } from 'react-router-dom';
import { ReCyCleTheme } from 're-cy-cle';
import configureSentry from './sentry';
import { PUBLIC_URL, configOverride } from 'helpers';
import { configureModal } from 'helpers/modal';
import { configureNotification } from 'helpers/notification';
import { configurePermission } from 'spider/component/Permission';
import { configureCurrentUser } from 'helpers/currentUser';
import { CancelButton, ApplyButton } from 'spider/semantic-ui/Button';
import { configureBasename, configureAfterSave } from 'spider/helpers/bindUrlParams';
import { Modal } from 'semantic-ui-react';
import RightDivider from 'spider/component/RightDivider';
import 'moment-duration-format';
import { api } from 'store/Base';
import { User } from 'store/User';
import { configureTranslation } from 'daycy';

import 'daycy/dist/daycy.css';
import 'style/semantic-ui/foo/bar/main.css';
import 'style/semantic-ui/daycy.css';
import 'style/semantic-ui.css';
import 'style/extra-icons.css';
import 'style/custom-icons/cy-custom-icons.css';

window.t = t;

configureTranslation((key, args) => {
    return t(`daycy.${key}`, args);
});

class SpecialViewStore extends ViewStore {
    @action
    handleBootstrap(res) {
        configOverride(res);
        return super.handleBootstrap(res);
    }

    parseCurrentUserFromBootstrap(res) {
        this.currentUser.fromBackend({
            data: res.user.data,
            repos: res.user.with,
            relMapping: res.user.with_mapping,
        });
    }
};


const viewStore = new SpecialViewStore({
	api,
	user: new User(null, {
        relations: ['groups.permissions']
    }),
	socketUrl: `${PUBLIC_URL || ''}/ws/`
});
window.viewStore = viewStore;

configureSentry(viewStore);
configureModal(viewStore);
configureNotification(viewStore);
configurePermission(viewStore);
configureBasename(PUBLIC_URL);
configureAfterSave({ goBack: false, createUrl: '/add' });
configureCurrentUser(viewStore);

@observer
class Root extends Component {
    @observable showAlert = false;
    @observable alertMessage = '';
    @observable alertConfirm = null;

    // Custom alert callbacks.
    @observable alertOnApply = null;
    @observable alertOnCancel = null;

    componentDidMount() {
        i18next.on('languageChanged', () => this.forceUpdate());
    }

    componentWillUnmount() {
        i18next.off('languageChanged');
    }

    cancel = () => {
        this.showAlert = false;

        if (this.alertOnCancel) {
            this.alertOnCancel();
        }

        this.alertConfirm(false);
        this.alertOnApply = null;
        this.alertOnCancel = null;
    }

    confirm = () => {
        this.showAlert = false;

        if (this.alertOnApply) {
            this.alertOnApply();
        }

        this.alertConfirm(true);
        this.alertOnApply = null;
        this.alertOnCancel = null;
    }

    render() {
        return (
            <React.Fragment key={i18next.language}>
                <Modal size="tiny" open={this.showAlert} centered={false}>
                    <Modal.Content style={{ textAlign: 'center' }}>
                        <p>{this.alertMessage}</p>
                        <p>{t('form.confirmQuestion')}</p>
                    </Modal.Content>
                    <Modal.Actions style={{ display: 'flex' }}>
                        <CancelButton negative onClick={this.cancel} />
                        <RightDivider />
                        <ApplyButton positive onClick={this.confirm} content={t('form.continueButton')} />
                    </Modal.Actions>
                </Modal>
                <ReCyCleTheme theme={theme}>
                    <BrowserRouter basename={PUBLIC_URL} getUserConfirmation={(message, confirm, ...args) => {
                        this.showAlert = true;
                        this.alertConfirm = confirm;

                        if (typeof message === 'object') {
                            this.alertMessage = message.message;
                            this.alertOnApply = message.onApply;
                            this.alertOnCancel = message.onCancel;
                        } else {
                            this.alertMessage = message;
                            this.alertOnApply = null;
                            this.alertOnCancel = null;
                        }

                    }}>
                        <App store={viewStore} />
                    </BrowserRouter>
                </ReCyCleTheme>
            </React.Fragment>
        );
    }
}

ReactDOM.render(
    <Root />,
    document.getElementById('root')
);
