import i18next from 'i18next';
import en from 'i18n/translation/en';
import nl from 'i18n/translation/nl';

i18next.init({
    lng: 'en',
    resources: {
        en: { description: 'English', translation: en },
        nl: { description: 'Nederlands', translation: nl },
    },
    interpolation: {
        escapeValue: false, // not needed for react as it does escape per default to prevent xss!
    },
});

export default i18next;
export const t = i18next.t.bind(i18next);
