import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import TrippyBackground from 'component/TrippyBackground';
import { Body, ContentContainer } from 're-cy-cle';
import { Header, Form, Button, Input, Message } from 'semantic-ui-react';
import { Sidebar } from './Admin/Overview';
import { Link } from 'react-router-dom';
import { theme } from 'styles';
import styled from 'styled-components';
import { lighten } from 'polished';

export const PrimaryLinkContainer = styled.div`
    text-align: right;
    margin-top: -0.25rem;
    margin-bottom: 0.75rem;
`;

export const PrimaryLink = styled(Link)`
    color: ${theme.primaryColor};
    &:hover {
        color: ${lighten(0.1, theme.primaryColor)};
    }
    font-weight: bold;
`;

export const TranslucentSidebar = styled(Sidebar)`
    background-color: rgba(238, 238, 238, 0.85);
`;

@observer
export default class Login extends Component {
    static propTypes = {
        viewStore: PropTypes.object,
        usernameField: PropTypes.string.isRequired,
    };

    static defaultProps = {
        usernameField: 'email',
    };

    @observable errorMsg = '';
    @observable input = { password: '', [this.props.usernameField]: '' };

    handleSubmit = () => {
        const { usernameField } = this.props;

        this.errorMsg = '';
        this.props.viewStore
            .performLogin(this.input[usernameField], this.input.password, { usernameField: usernameField })
            .catch(err => {
                const status = err.response && err.response.status;
                if (status === 403) {
                    this.errorMsg = t('user.login.errors.invalidCredentials');
                } else {
                    console.error(err);
                    this.errorMsg = t('user.login.errors.unknown', {
                        status: status || err.message,
                    });
                }
            });
    };

    renderForm() {
        const { usernameField } = this.props;

        return (
            <Form error={this.errorMsg !== ''} style={{ marginTop: '1.5rem' }}>
                <Form.Field>
                    <label>{t(`user.field.${usernameField}.label`)}</label>
                    <Input
                        name={usernameField}
                        value={this.input[usernameField]}
                        onChange={(e, { value }) => this.input[usernameField] = value}
                        autoFocus
                    />
                </Form.Field>
                <Form.Field>
                    <label>{t('user.field.password.label')}</label>
                    <Input
                        name="password"
                        type="password"
                        value={this.input.password}
                        onChange={(e, { value }) => this.input.password = value}
                    />
                </Form.Field>
                <PrimaryLinkContainer>
                    <PrimaryLink to="/login/forgot">
                        {t('user.login.forgotPasswordLink')}
                    </PrimaryLink>
                </PrimaryLinkContainer>
                <Message error content={this.errorMsg} />
                <Button data-test-login primary fluid
                    content={t('user.login.loginButton')}
                    onClick={this.handleSubmit}
                />
            </Form>
        );
    }

    render() {
        return (
            <Body>
                <ContentContainer>
                    <TrippyBackground>
                        <div style={{ flex: '1' }} />
                        <TranslucentSidebar>
                            <Header as="h2" content={t('user.login.title')} />
                            {this.renderForm()}
                        </TranslucentSidebar>
                    </TrippyBackground>
                </ContentContainer>
            </Body>
        );
    }
}
