import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { computed, reaction, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Model, Store } from 'mobx-spine';
import { Image, Label, Dropdown, Form, Icon, Input, Button, Checkbox, TextArea, Select } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { pick, omit, debounce } from 'lodash';
import moment from 'moment';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { MultiPick as BaseMultiPick } from 're-cy-cle';
import onClickOutside from 'react-onclickoutside';
import { DatePicker, DateRangePicker } from 'daycy';
import { snakeToCamel, ACTION_DELAY } from '../helpers';
import { DateTime, Interval } from 'luxon';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import AutosizeTextarea from 'react-textarea-autosize';
import RightDivider from '../component/RightDivider';
import Dropzone from 'react-dropzone';

const LabelLink = styled(Link)`
    color: rgba(0, 0, 0, 0.4) !important;
    text-decoration: none !important;
`;

export class ErrorLabel extends Label {
    static defaultProps = {
        pointing: true,
        color: 'red',
    }
};

export const FormLabel = styled.label`
    display: flex !important;
    align-items: center;
`;

export const FormSubLabel = styled.span`
    font-weight: normal;
    opacity: 0.7;
    display: inline-block;
    margin-left: auto;
`;

function getId(obj) {
    return obj.id;
}

function sliceArray(array) {
    return array.slice();
}

// Base Class

@observer
export class TargetBase extends Component {
    static propTypes = {
        // Base
        target: PropTypes.oneOf([
            PropTypes.instanceOf(Model),
            PropTypes.instanceOf(Store),
        ]).isRequired,
        name: PropTypes.string.isRequired,
        // Controlled component
        value: PropTypes.any,
        onChange: PropTypes.func,
        afterChange: PropTypes.func.isRequired,
        // Value
        toTarget: PropTypes.func.isRequired,
        fromTarget: PropTypes.func.isRequired,
        // Errors
        mapErrors: PropTypes.func.isRequired,
        errorProps: PropTypes.object.isRequired,
        // Rendering
        label: PropTypes.string,
        subLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        viewTo: PropTypes.func,
        contentProps: PropTypes.object.isRequired,

        className: PropTypes.string,
        width: PropTypes.number,
        required: PropTypes.bool,
        inline: PropTypes.bool,
        noLabel: PropTypes.bool,
        errors: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        toTarget: (value) => value,
        fromTarget: (value) => value,
        afterChange: () => {},
        mapErrors: (errors) => errors,
        contentProps: {},
        errorProps: {},
        noLabel: false,
    };

    constructor(...args) {
        super(...args);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    @computed get type() {
        const { target } = this.props;

        return (
            target instanceof Model
            ? 'model'
            : target instanceof Store
            ? 'store'
            : undefined
        );
    }

    // Value conversion

    toModel(value) {
        return value;
    }

    toStore(value) {
        return value;
    }

    toTarget(value) {
        const { toTarget } = this.props;
        // User conversion
        value = toTarget(value);
        // Class conversion
        switch (this.type) {
            case 'model':
                value = this.toModel(value);
                break;
            case 'store':
                value = this.toStore(value);
                break;
            default:
                // nop
        }
        return value;
    }

    fromModel(value) {
        return value;
    }

    fromStore(value) {
        return value;
    }

    fromTarget(value) {
        const { fromTarget } = this.props;
        // Class conversion
        switch (this.type) {
            case 'model':
                value = this.fromModel(value);
                break;
            case 'store':
                value = this.fromStore(value);
                break;
            default:
                // nop
        }
        // User conversion
        value = fromTarget(value);
        return value;
    }

    // Value handling

    getValue() {
        let { value } = this.props;

        // Get base value
        if (value === undefined) {
            value = this.getValueBase();
        }
        // Transform
        value = this.fromTarget(value);
        return value;
    }

    getValueBase() {
        const { target, name } = this.props;

        switch (this.type) {
            case 'model':
                return target[name];
            case 'store':
                return target.params[name];
            default:
                // nop
        }
    }

    setValue(value) {
        const { target, name } = this.props;

        switch (this.type) {
            case 'model':
                target.setInput(name, value);
                break;
            case 'store':
                if (value === undefined) {
                    delete target.params[name];
                } else {
                    target.params[name] = value;
                }
                break;
            default:
                // nop
        }
    }

    @computed get value() {
        return this.getValue();
    }

    onChange(value) {
        const { onChange, target, afterChange } = this.props;

        value = this.toTarget(value);

        if (onChange) {
            onChange(value);
        } else {
            this.setValue(value);
        }

        afterChange(value, target);
    }

    // Errors

    @computed get errors() {
        let { target, name, mapErrors, errors } = this.props;

        if (!errors && this.type === 'model') {
            errors = (
                target &&
                target.backendValidationErrors &&
                target.backendValidationErrors[name]
            );
        }

        return mapErrors(errors || []);
    }

    // Rendering

    getLabel() {
        const { label, target, name } = this.props;

        if ('label' in this.props) {
            return label;
        }

        if (!name) {
            return '';
        }

        if (this.type === 'model') {
            const model = snakeToCamel(target.constructor.backendResourceName.replace(/\//g, '_'));

            return t(`${model}.field.${name}.label`);
        }

        return '';
    }

    getSubLabel() {
        if ('subLabel' in this.props) {
            return this.props.subLabel;
        }
    }

    renderViewTo() {
        const { target, viewTo } = this.props;

        if (!viewTo) {
            return null;
        }

        let res = viewTo;
        if (typeof viewTo === "function") {
            res = res(this.model, target);
        }
        if (typeof res === "string") {
            res = (
                <LabelLink to={res}>
                    <Icon name="eye" />
                </LabelLink>
            );
        }

        return (
            <React.Fragment>&nbsp;{res}</React.Fragment>
        );
    }

    renderLabel() {
        const label = this.getLabel();
        const subLabel = this.getSubLabel();

        return (
            <FormLabel data-test-form-label>
                {label}
                {subLabel && (
                    <FormSubLabel>{subLabel}</FormSubLabel>
                )}
                {this.renderViewTo()}
            </FormLabel>
        );
    }

    renderError(error, i) {
        return (
            <div key={i}>{error}</div>
        );
    }

    renderErrors(props) {
        if (this.errors.length === 0) {
            return null;
        }

        return (
            <ErrorLabel {...props}>
                {this.errors.map(this.renderError)}
            </ErrorLabel>
        );
    }

    renderContent(props) {
        // Override this using this.value and this.onChange
    }

    render() {
        const { required, width, inline, className, contentProps, errorProps, noLabel, ...rest } = this.props;

        const props = {
            ...contentProps,
            ...omit(
                rest,
                'target', 'value', 'onChange', 'afterChange',
                'toTarget', 'fromTarget', 'label', 'viewTo', 'mapErrors',
                'noLabel',
            ),
        };

        const errors = this.renderErrors(errorProps);

        return (
            <Form.Field
                required={required}
                error={this.errors.length > 0}
                width={width}
                inline={inline}
                className={className}
            >
                {!noLabel && this.renderLabel()}
                {errorProps.pointing === 'right' && errors}
                {this.renderContent(props)}
                {errorProps.pointing !== 'right' && errors}
            </Form.Field>
        );
    }
}

// Text Input

export class TargetTextInput extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.string,
    };

    fromStore(value) {
        return value || '';
    }

    toStore(value) {
        return value === '' ? undefined : value;
    }

    onChange(e, { value }) {
        super.onChange(value);
    }

    renderContent(props) {
        return (
            <Input
                value={this.value}
                onChange={this.onChange}
                {...props}
            />
        );
    }
}

export class TargetTextArea extends TargetTextInput {
    static propTypes = {
        ...TargetTextInput.propTypes,
        autoHeight: PropTypes.bool,
    };

    renderContent(props) {
        const { autoHeight, ...rest } = props;
        const autoHeightProps = autoHeight ? { as: AutosizeTextarea } : {};

        return (
            <TextArea
                value={this.value}
                onChange={this.onChange}
                {...autoHeightProps}
                {...rest}
            />
        );
    }
}

// Date Input

export class TargetDatePicker extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.instanceOf(moment),
    };

    toModel(value) {
        if (!value) {
            return null;
        }
        return luxonToMoment(value);
    }

    fromModel(value) {
        if (!value) {
            return null;
        }
        return momentToLuxon(value);
    }

    toStore(value) {
        if (!value) {
            return undefined;
        }
        return value.toFormat(LUXON_SERVER_DATE_FORMAT);
    }

    fromStore(value) {
        if (!value) {
            return null;
        }
        return DateTime.fromFormat(value, LUXON_SERVER_DATE_FORMAT);
    }

    renderContent(props) {
        return (
            <DatePicker
                value={this.value}
                onChange={this.onChange}
                {...props}
            />
        );
    }
}

// Multi Pick

const MultiPickContainer = styled.div`
    width: 100%;
    position: relative;
    box-sizing: border-box;
`;

const MultiPickButton = styled(Button)`
    margin: 0;
    width: 100%;
`;

// So to be able to extend MultiPick we have to unwrap it from onClickOutside,
// extend it, and then wrap it again
@onClickOutside
class MultiPick extends BaseMultiPick.getClass() {
    render() {
        const props = omit(
            this.props,
            'options',
            'value',
            'searchAppearsAfterCount',
            'searchPlaceholder',
            'selectedText',
            'selectAllText',
            'selectNoneText',
            'noneSelectedText',
            'onChange',
            'disabled',
            'noBatchSelect',
        );
        return (
            <MultiPickContainer>
                <MultiPickButton
                    onClick={this.handleToggle}
                    disabled={this.props.disabled}
                    content={this.generateButtonText()}
                    icon="angle down"
                    labelPosition="right"
                    size="small"
                    {...props}
                />
                {this.renderDropdown()}
            </MultiPickContainer>
        );
    }
}

export class TargetMultiPick extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.arrayOf(PropTypes.any),
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            text: PropTypes.string.isRequired,
        })).isRequired,
        // Used for converting the value in the query parameter
        type: PropTypes.oneOf(['str', 'int']),
        fromParam: PropTypes.func,
        toParam: PropTypes.func,
        store: PropTypes.instanceOf(Store),
    };

    static defaultProps = {
        ...TargetBase.defaultProps,
    };

    constructor(...args) {
        super(...args);
        this.fromParam = this.fromParam.bind(this);
        this.toParam = this.toParam.bind(this);
    }

    @computed get valueType() {
        const { type, store } = this.props;
        if (type) {
            return type;
        } else if (store) {
            return 'int';
        } else {
            return 'str';
        }
    }

    fromParam(value) {
        const { fromParam } = this.props;

        if (fromParam) {
            return fromParam(value);
        }

        switch (this.valueType) {
            case 'str':
                return value;
            case 'int':
                return parseInt(value);
            default:
                throw new Error(`Invalid type: ${this.valueType}`);
        }
    }

    toParam(value) {
        const { toParam } = this.props;

        if (toParam) {
            return toParam(value);
        }

        switch (this.valueType) {
            case 'str':
                return value;
            case 'int':
                return value.toString();
            default:
                throw new Error(`Invalid type: ${this.type}`);
        }
    }

    toStore(value) {
        if (value.length === 0) {
            return undefined;
        }

        return value.map(this.toParam).join(',');
    }

    fromStore(value) {
        if (value === undefined) {
            return [];
        }

        return value.toString().split(',').map(this.fromParam);
    }

    fromModel(value) {
        const { store } = this.props;
        if (store) {
            value = value.map(getId);
        }
        value = super.fromModel(value);
        return value;
    }

    toModel(value) {
        const { store } = this.props;
        value = super.toModel(value);
        if (store) {
            value = store.getByIds(value);
        }
        return value;
    }

    @computed get options() {
        const { options } = this.props;
        return options.map(({ value, text }) => ({
            value: value,
            label: text,
        }));
    }

    renderContent({ options, ...props }) {
        return (
            <MultiPick
                value={this.value}
                onChange={this.onChange}
                options={this.options}
                {...props}
            />
        );
    }
}

// Radio Buttons

export class TargetRadioButtons extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            text: PropTypes.string.isRequired,
        })).isRequired,
        activeProps: PropTypes.object,
        inactiveProps: PropTypes.object,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        ...TargetBase.defaultProps,
        activeProps: { active: true },
        inactiveProps: {},
        disabled: false,
    };

    constructor(...args) {
        super(...args);
        this.renderOption = this.renderOption.bind(this);
    }

    renderOption({ value, text, ...rest }, i) {
        const { activeProps, inactiveProps, disabled } = this.props;
        const props = value === this.value ? activeProps : inactiveProps;

        return (
            <Button
                key={value || i}
                onClick={() => this.onChange(value)}
                content={text}
                disabled={disabled}
                {...props}
                {...rest}
            />
        );
    }

    renderContent({ options, ...props }) {
        delete props.activeProps;
        delete props.inactiveProps;
        delete props.disabled;
        return (
            <Button.Group widths={options.length} size="small" {...props}>
                {options.map(this.renderOption)}
            </Button.Group>
        );
    }
}

// Date Range Picker

const LUXON_SERVER_DATE_FORMAT = 'yyyy-LL-dd';

export function momentToLuxon(value) {
    return DateTime.fromISO(value.toISOString());
}

export function luxonToMoment(value) {
    return moment(value.toISO());
}

export class TargetDateRangePicker extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.instanceOf(Interval),
        startName: PropTypes.string,
        endName: PropTypes.string,
    };

    getValueBase() {
        const { target, startName, endName } = this.props;

        if (!startName || !endName) {
            return super.getValueBase();
        }

        let start, end;
        switch (this.type) {
            case 'model':
                start = target[startName];
                end = target[endName];
                if (start && end) {
                    return { start, end };
                } else {
                    return null;
                }
            case 'store':
                start = target.params[startName];
                end = target.params[endName];
                if (start && end) {
                    return `${start},${end}`;
                } else {
                    return undefined;
                }
            default:
                // nop
        }
    }

    setValue(value) {
        const { target, startName, endName } = this.props;

        if (!startName || !endName) {
            super.setValue(value);
        }

        switch (this.type) {
            case 'model':
                const { start, end } = value;
                target.setInput(startName, start);
                target.setInput(endName, end);
                break;
            case 'store':
                if (value === undefined) {
                    delete target.params[startName];
                    delete target.params[endName];
                } else {
                    const [start, end] = value.split(',');
                    target.params[startName] = start;
                    target.params[endName] = end;
                }
                break;
            default:
                // nop
        }
    }

    fromModel(value) {
        if (!value) {
            return null;
        }
        const { start, end } = value;
        return Interval.fromDateTimes(
            momentToLuxon(start),
            momentToLuxon(end),
        );
    }

    toModel(value) {
        if (!value) {
            return null;
        }
        return {
            start: luxonToMoment(value.start),
            end: luxonToMoment(value.end),
        };
    }

    fromStore(value) {
        if (value === undefined) {
            return null;
        }

        let [start, end] = value.split(',');

        return Interval.fromDateTimes(
            DateTime.fromFormat(start, LUXON_SERVER_DATE_FORMAT),
            DateTime.fromFormat(end, LUXON_SERVER_DATE_FORMAT),
        );
    }

    toStore(value) {
        if (!value) {
            return undefined;
        }

        const start = value.start.toFormat(LUXON_SERVER_DATE_FORMAT);
        const end = value.end.toFormat(LUXON_SERVER_DATE_FORMAT);
        return `${start},${end}`;
    }

    renderContent(props) {
        return (
            <DateRangePicker
                value={this.value}
                onChange={this.onChange}
                {...props}
            />
        );
    }
}

// Multi Text Input

@observer
class MultiTextInput extends Component {
    static propTypes = {
        value: PropTypes.arrayOf(PropTypes.string).isRequired,
        onChange: PropTypes.func.isRequired,
    };

    constructor(...args) {
        super(...args);
        this.onChange = this.onChange.bind(this);
    }

    @computed get options() {
        const { value } = this.props;
        return value.map((item) => ({ value: item, text: item }));
    }

    onChange(e, { value }) {
        const { onChange } = this.props;
        onChange(value);
    }

    onAddItem() {
        // no op
    }

    render() {
        const { value, onChange, ...props } = this.props;
        return (
            <Dropdown
                selection fluid multiple search allowAdditions
                icon={null} noResultsMessage={null}
                options={this.options}
                value={value}
                onAddItem={this.onAddItem}
                onChange={this.onChange}
                {...props}
            />
        );
    }
}

export class TargetMultiTextInput extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.arrayOf(PropTypes.string),
    };

    fromStore(value) {
        if (value === undefined) {
            return [];
        }

        return value.split(',');
    }

    toStore(value) {
        if (value.length === 0) {
            return undefined;
        }

        return value.join(',');
    }

    renderContent(props) {
        return (
            <MultiTextInput
                value={this.value}
                onChange={this.onChange}
                {...props}
            />
        );
    }
}

export class TargetCheckbox extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.bool,
    };

    onChange(e, { checked }) {
        super.onChange(checked);
    }

    fromStore(value) {
        return value === 'true';
    }

    toStore(value) {
        return value ? 'true' : 'false';
    }

    renderContent(props) {
        return (
            <Checkbox
                checked={this.value}
                onChange={this.onChange}
                {...props}
            />
        );
    }
}

// Number Input

const StyledInput = styled(Input)`
    > input {
        text-align: ${(props) => props.textAlign} !important;
    }
`;

const PROPS_MASK = [
    'prefix',
    'suffix',
    'includeThousandsSeparator',
    'thousandsSeparatorSymbol',
    'allowDecimal',
    'allowNegative',
    'decimalSymbol',
    'decimalLimit',
];

export class TargetNumberInput extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.number,
        prefix: PropTypes.string,
        suffix: PropTypes.string,
        includeThousandsSeperator: PropTypes.bool,
        thousandsSeparatorSymbol: PropTypes.string,
        allowDecimal: PropTypes.bool,
        allowNegative: PropTypes.bool,
        decimalSymbol: PropTypes.string,
        decimalLimit: PropTypes.number,
        textAlign: PropTypes.oneOf(['left', 'center', 'right']),
        createMask: PropTypes.func,
        createReverseMask: PropTypes.func,
        toFixed: PropTypes.bool,
    };

    static defaultProps = {
        ...TargetBase.defaultProps,
        allowDecimal: false,
        includeThousandsSeparator: false,
        prefix: '',
        suffix: '',
        thousandsSeparatorSymbol: '.',
        decimalSymbol: ',',
        textAlign: 'right',
        toFixed: false,
    };

    @observable localValue = '';
    ignoreReactionOnce = false;

    constructor(...args) {
        super(...args);

        const { decimalLimit, toFixed } = this.props;

        this.initLocalValue = this.initLocalValue.bind(this);
        this.renderInput = this.renderInput.bind(this);

        if (toFixed && decimalLimit === undefined) {
            throw new Error('decimalLimit is required for toFixed');
        }
    }

    componentDidMount() {
        this.initLocalValueReaction = reaction(
            () => this.value,
            this.initLocalValue,
            { fireImmediately: true },
        );
    }

    componentWillUnmount() {
        this.initLocalValueReaction();
    }

    initLocalValue() {
        if (!this.ignoreReactionOnce) {
            const { mask } = this.createMask(this.props);
            this.localValue = conformToMask(this.value, mask).conformedValue;
        }
        this.ignoreReactionOnce = false;
    }

    onChange(e) {
        this.localValue = e.target.value;
        this.ignoreReactionOnce = true;
        super.onChange(this.localValue);
    }

    fromStore(value) {
        return value || '';
    }

    toStore(value) {
        return value === '' ? undefined : value;
    }

    fromModel(value) {
        if (typeof value !== 'number') {
            return '';
        }
        return value.toString();
    }

    normalizeValue(value) {
        if (value === '') {
            return '';
        }

        const { reverseMask } = this.createReverseMask(this.props);
        value = reverseMask(value);
        return value;
    }

    toModel(value) {
        if (value === '') {
            return null;
        }
        if (value.includes('.')) {
            return parseFloat(value);
        } else {
            return parseInt(value);
        }
    }

    fromTarget(value) {
        const { toFixed, decimalLimit, decimalSymbol } = this.props;
        value = super.fromTarget(value);
        if (value !== '' && toFixed) {
            value = (parseInt(value) / Math.pow(10, decimalLimit)).toFixed(decimalLimit);
            // value = trimEnd(value, '0');
        }
        value = value.replace('.', decimalSymbol);
        return value;
    }

    toTarget(value) {
        const { toFixed, decimalLimit } = this.props;
        if (value !== '' && toFixed) {
            value = (parseFloat(this.normalizeValue(value)) * Math.pow(10, decimalLimit)).toFixed();
        }
        value = super.toTarget(value);
        return value;
    }

    createMask(props) {
        if (props.createMask) {
            return props.createMask(props);
        }

        return {
            mask: createNumberMask(pick(props, PROPS_MASK)),
            props: omit(props, PROPS_MASK),
        };
    }

    createReverseMask(props) {
        if (props.createReverseMask) {
            return props.createReverseMask(props);
        }

        const { prefix, suffix, thousandsSeparatorSymbol, decimalSymbol, allowDecimal } = props;

        return {
            reverseMask: (value) => {
                let negative;
                if (value.startsWith('-')) {
                    value = value.slice(1);
                    negative = true;
                } else {
                    negative = false;
                }

                value = value.slice(prefix.length, value.length - suffix.length);

                if (negative) {
                    value = '-' + value;
                }

                let oldValue;
                do {
                    oldValue = value;
                    value = oldValue.replace(thousandsSeparatorSymbol, '');
                } while (value !== oldValue)

                if (allowDecimal) {
                    value = value.replace(decimalSymbol, '.');
                }
                return value;
            },
            props: omit(props, PROPS_MASK),
        }
    }

    renderInput(ref, { defaultValue, ...props }) {
        return (
            <StyledInput
                ref={(node) => {
                    let domNode = ReactDOM.findDOMNode(node);
                    if (domNode) {
                        domNode = domNode.children[0];
                    }
                    return ref(domNode);
                }}
                value={defaultValue}
                {...props}
            />
        );
    }

    renderContent(props) {
        const { props: otherProps, mask } = this.createMask(props);
        return (
            <MaskedInput
                mask={mask}
                value={this.localValue}
                onChange={this.onChange}
                onBlur={this.initLocalValue}
                render={this.renderInput}
                {...otherProps}
            />
        );
    }
}

export class TargetMoneyInput extends TargetNumberInput {
    static defaultProps = {
        ...TargetNumberInput.defaultProps,
        allowDecimal: true,
        decimalLimit: 2,
        includeThousandsSeparator: true,
        prefix: '€',
    };
}

export class TargetPercentageInput extends TargetNumberInput {
    static defaultProps = {
        ...TargetNumberInput.defaultProps,
        allowDecimal: true,
        decimalLimit: 1,
        suffix: '%',
    };
}

// File uploads

function getFileTypeFromUrl(url) {
    let search = null;

    if (url) {
        const pos = url.indexOf('?');
        if (pos !== -1) {
            search = url.slice(pos);
        }
    }

    if (search) {
        const urlSearchParams = new URLSearchParams(search);
        return urlSearchParams.get('content_type');
    } else {
        return null;
    }
}


const PreviewContainer = styled.div`
    margin-top: 0.5em;
`;


@observer
export class FilePreview extends Component {
    static propTypes = {
        url: PropTypes.string.isRequired,
    };

    @computed get type() {
        const { url } = this.props;
        return getFileTypeFromUrl(url);
    }

    renderImagePreview() {
        const { url, ...props } = this.props;
        return (
            <PreviewContainer>
                <Image src={url} {...props} />
            </PreviewContainer>
        );
    }

    render() {
        if (this.type && this.type.startsWith('image/')) {
            return this.renderImagePreview();
        } else {
            return null;
        }
    }
}


const StyledDropzone = styled(Dropzone)`
    cursor: pointer;
    width: unset;
    height: unset;
    border: unset;
`;


const FileContainer = styled.div`
    display: flex;
    align-items: center;
    > .ui.button:last-child {
        margin-right: 0 !important;
    }
`;


export class TargetFile extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        target: PropTypes.instanceOf(Model).isRequired,
        value: PropTypes.string,
        noPreview: PropTypes.bool,
        disabled: PropTypes.bool,
        deletable: PropTypes.bool,
        onDelete: PropTypes.func,
    };

    static defaultProps = {
        ...TargetBase.defaultProps,
        noPreview: false,
        disabled: false,
    };

    constructor(...args) {
        super(...args);
        this.onDelete = this.onDelete.bind(this);
    }

    @computed get fileType() {
        return getFileTypeFromUrl(this.value);
    }

    @computed get deletable() {
        const { deletable } = this.props;
        return deletable === undefined ? !!this.value : deletable;
    }

    onChange(files) {
        for (const file of files) {
            super.onChange(file);
        }
    }

    onDelete() {
        const { onDelete } = this.props;

        if (onDelete) {
            onDelete();
        } else {
            super.onChange(null);
        }
    }

    renderContent({ noPreview, disabled, ...props }) {
        let icon, text;

        if (this.value === null) {
            icon = null;
            text = <i>{t('form.fileType.none')}</i>;
        } else if (this.fileType && this.fileType.startsWith('image/')) {
            icon = 'file image outline';
            text = t('form.fileType.image');
        } else if (this.fileType === 'application/pdf') {
            icon = 'file pdf outline';
            text = t('form.fileType.pdf');
        } else {
            icon = 'file outline';
            text = t('form.fileType.any');
        }

        return (
            <React.Fragment>
                <FileContainer>
                    {icon && <Icon name={icon} size="large" />} {text}
                    <RightDivider />
                    <StyledDropzone onDrop={this.onChange}>
                        <Button
                            icon="upload"
                            disabled={disabled}
                        />
                    </StyledDropzone>
                    <Button icon="download"
                        as="a" href={this.value} download
                        disabled={!this.value}
                    />
                    <Button
                        icon="delete"
                        onClick={this.onDelete}
                        disabled={disabled || !this.deletable}
                    />
                </FileContainer>
                {!noPreview && <FilePreview url={this.value} {...props} />}
            </React.Fragment>
        );
    }
}


// TargetSelect

export class TargetSelect extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            text: PropTypes.string.isRequired,
        })),
        toOption: PropTypes.function,
        // Used for converting the value in the query parameter
        type: PropTypes.oneOf(['str', 'int']),
        store: PropTypes.instanceOf(Store),
        // Remote settings
        remote: PropTypes.bool,
        searchKey: PropTypes.string,
        skipFetch: PropTypes.bool,
        // Multiple settings
        multiple: PropTypes.bool,
    };

    static defaultProps = {
        ...TargetBase.defaultProps,
        remote: false,
        searchKey: 'search',
        skipFetch: false,
        multiple: false,
    };

    constructor(...args) {
        super(...args);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.toStoreBase = this.toStoreBase.bind(this);
        this.fromStoreBase = this.fromStoreBase.bind(this);
        this.toModelBase = this.toModelBase.bind(this);
        this.fromModelBase = this.fromModelBase.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        this.setDebouncedFetchReaction = reaction(
            () => this.props.store,
            (store) => {
                if (store) {
                    this.debouncedFetch = debounce(
                        store.fetch.bind(store),
                        ACTION_DELAY,
                    );
                } else {
                    delete this.debouncedFetch;
                }
            },
            { fireImmediately: true },
        );

        const { remote, store, skipFetch } = this.props;

        if (remote && store && !skipFetch) {
            store.fetch();
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.setDebouncedFetchReaction();
        if (this.valueStoreReaction) {
            this.valueStoreReaction();
        }
    }

    @computed get valueType() {
        const { type, store } = this.props;
        if (type) {
            return type;
        } else if (store) {
            return 'int';
        } else {
            return 'str';
        }
    }

    toStore(value) {
        const { multiple } = this.props;

        if (multiple) {
            if (value === []) {
                return undefined;
            }
            value = value.map(this.toStoreBase).join(',');
        } else {
            if (value === null) {
                return undefined;
            }
            value = this.toStoreBase(value);
        }

        return value;
    }

    toStoreBase(value) {
        value = super.toStore(value);
        switch (this.valueType) {
            case 'str':
                break;
            case 'int':
                value = value.toString();
                break;
            default:
                throw new Error(`Invalid type: ${this.type}`);
        }
        return value;
    }

    fromStore(value) {
        const { multiple } = this.props;

        if (multiple) {
            if (value === undefined) {
                return [];
            }
            value = value.split(',').map(this.fromStoreBase);
        } else {
            if (value === undefined) {
                return null;
            }
            value = this.fromStoreBase(value);
        }

        return value;
    }

    fromStoreBase(value) {
        switch (this.valueType) {
            case 'str':
                break;
            case 'int':
                value = parseInt(value);
                break;
            default:
                throw new Error(`Invalid type: ${this.valueType}`);
        }
        value = super.fromStore(value);
        return value;
    }

    fromModel(value) {
        const { multiple } = this.props;

        if (multiple) {
            value = value.map(this.fromModelBase);
        } else {
            value = this.fromModelBase(value);
        }

        return value;
    }

    fromModelBase(value) {
        const { store } = this.props;
        if (store && value) {
            value = value.id;
        }
        value = super.fromModel(value);
        return value;
    }

    toModel(value) {
        const { multiple } = this.props;

        if (multiple) {
            value = value.map(this.toModelBase);
        } else {
            value = this.toModelBase(value);
        }

        return value;
    }

    toModelBase(value) {
        const { store } = this.props;
        value = super.toModel(value);
        if (store && value) {
            value = store.get(value) || this.valueStore.get(value);
        }
        return value;
    }

    onChange(e, { value }) {
        return super.onChange(value);
    }

    onSearchChange(e, { searchQuery }) {
        const { store, searchKey } = this.props;
        store.params[searchKey] = searchQuery;
        this.debouncedFetch();
    }

    valueStoreReaction = null;

    @computed get valueStore() {
        const { store, remote, multiple } = this.props;

        if (!multiple || !remote) {
            return undefined;
        }

        if (this.valueStoreReaction) {
            this.valueStoreReaction();
        }

        if (this.type === 'model') {
            return this.getValueBase();
        } else {
            const Store = store.constructor;
            const valueStore = new Store();
            this.valueStoreReaction = reaction(
                () => this.value,
                (value) => {
                    valueStore.params['.id:in'] = value.join(',');
                    valueStore.fetch();
                },
                { fireImmediately: true },
            );
            return valueStore;
        }
    }

    getOptions(props) {
        const { options, store, toOption } = props;

        if (options) {
            return options;
        } else if (store && toOption) {
            let res = store.map(toOption);
            if (this.valueStore) {
                res = res.concat(this.valueStore.map(toOption));
            }
            return res;
        } else {
            throw new Error('couldn\'t derive options');
        }
    }

    @computed get options() {
        return this.getOptions(this.props);
    }

    renderContent({ remote, ...props }) {
        delete props.options;
        delete props.toOption;

        if (remote) {
            props.search = sliceArray;
            props.onSearchChange = this.onSearchChange;
        }

        return (
            <Select
                value={this.value}
                onChange={this.onChange}
                options={this.options}
                {...props}
            />
        );
    }
}
